<template>
  <BaseSelect
    :id="id"
    :value="value"
    :options="options"
    label="name"
    :reduce="reduce"
    placeholder="Selecciona un grupo de categoría"
    @input="$emit('input', $event)"
  />
</template>

<script>
import CategoriesApi from '@/api/categories-api'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'CategoryGroupSelect',
  components: { BaseSelect },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    reduce: {
      type: Function,
      default: item => item,
    },
  },
  data() {
    return {
      options: [],
    }
  },
  async mounted() {
    try {
      const response = await CategoriesApi.getCategoryGroups()
      this.options = response.data
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style scoped>

</style>
