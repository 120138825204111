<template>
  <b-form-group
    :label="label"
    :label-for="id"
  >
    <b-form-radio-group :id="id">
      <span
        v-for="(option, index) in options"
        :key="index + option.text"
        @click.prevent="handleFormRadioClick(option.value)"
      >
        <b-form-radio
          ref="form-radio"
          :checked="value"
          :value="option.value"
        >
          {{ option.text }}
        </b-form-radio>
      </span>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'BaseFormRadioGroup',
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleFormRadioClick(clickedValue) {
      const selectedValue = clickedValue !== this.value ? clickedValue : null
      this.$emit('input', selectedValue)
    },
  },
}
</script>

<style scoped>
</style>
