export default {
  today() {
    return this.fromDateToString(new Date())
  },
  fromDateToString(date) {
    if (!date || !(date instanceof Date)) {
      return
    }

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  },
  getDifferenceInDays(firstDate, secondDate) {
    if (!firstDate || !(firstDate instanceof Date) || !secondDate || !(secondDate instanceof Date)) {
      return
    }

    const diffInMs = Math.abs(firstDate - secondDate)
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24))
  },
}
