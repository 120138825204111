import { render, staticRenderFns } from "./BaseFormRadioGroup.vue?vue&type=template&id=ec97af58&scoped=true&"
import script from "./BaseFormRadioGroup.vue?vue&type=script&lang=js&"
export * from "./BaseFormRadioGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec97af58",
  null
  
)

export default component.exports