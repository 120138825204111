<template>
  <BaseFiltersSideBar
    v-model="model"
    @filters-submit="onFiltersSubmit"
  >
    <PurchaseInvoicesListFiltersForm
      ref="purchase-invoices-list-filters-form"
      v-model="filters"
      @submit="onFiltersSubmit"
    />
  </BaseFiltersSideBar>
</template>

<script>
import BaseFiltersSideBar from '@/components/ui/sidebar/BaseFiltersSideBar.vue'
import PurchaseInvoicesListFiltersForm from '@/components/purchase-invoices/form/PurchaseInvoicesListFiltersForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PurchaseInvoicesListFilters',
  components: {
    PurchaseInvoicesListFiltersForm,
    BaseFiltersSideBar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {},
    }
  },
  computed: {
    ...mapGetters({
      storeFilters: 'purchaseInvoice/getFilters',
    }),
    model: {
      get() {
        return this.value
      },
      set(newValue) {
         // lipia el formulario si no hay ningun filtro guardado en el store
        if(newValue && Object.keys(this.storeFilters).length === 0) {          
          this.filters = {}
          this.$refs['purchase-invoices-list-filters-form'].clearForm()
        }
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    clearFilters() {
      this.$refs['purchase-invoices-list-filters-form'].clearForm()
    },
    onFiltersSubmit() {
      this.model = false
      this.$emit('filters-submit', this.filters)
    },
  },
}
</script>

<style scoped>
</style>
