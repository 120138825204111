<template>
  <div>
    <BaseTable
      ref="purchase-invoices-table"
      resource="purchase-invoices"
      :resourceStore="'purchaseInvoice'"
      :columns="columns"
      :filters="baseFilters"
      :hide-toolbar="hideToolbar"
      :totals-configuration="totalsConfiguration"
      :loading="loading || localLoading"
      :disable-pagination="disablePagination"
      :excluded-filters="baseExcludeFilters"
      @filter-button-click="filtersVisible = !filtersVisible"
      @row-clicked="
        $router.push({
          name: 'viewPurchaseInvoice',
          params: { id: $event.id },
        })
      "
      @on-clear-active-filters="handleClearListFilters"
      @data-loaded="$emit('data-loaded', $event)"
    >
      <template #cell(job)="{ value }">
        <span>{{ value ? value.code : "" }}</span>
      </template>
      <template #cell(provider)="{ value }">
        <span>{{ value.name }}</span>
      </template>
      <template #cell(invoice_date)="{ value, item }">
        <div>{{ value | formatDate }}</div>
        <div v-show="item.expiration_date" class="font-small text-light">
          vence {{ item.expiration_date | formatDate }}
        </div>
      </template>
      <template #cell(expiration_date)="{ value, item }">
        <span
          v-show="
            item.status.name === 'Pendiente de pago' ||
            item.status.name === 'Pago parcial'
          "
          :class="
            (isDueDateExpired(value) ? 'text-danger' : 'text-primary') +
            ' text-uppercase'
          "
        >
          {{ getDueDateDaysText(value) }}
        </span>
      </template>
      <template #cell(status)="data">
        <StatusBadge 
          :status="data.item.status.name" 
          :text="data.item.status.name === 'Pago parcial' ? 
            `Pago parcial ${data.item.percent !== 0 && data.item.percent !== 100 ? `${data.item.percent} % ` : '' }` 
          : null" 
        />
        <StatusBadge
          v-if="data.item.send_payment_document && data.item.send_payment_document === 1"
          class="mt-1" 
          :status="'Envio doc. pago'" 
          :text="'Envio doc. pago'"
          :variant="'light-success'"
        />
      </template>
      <template #cell(delivery_note_received)="data">
        <b-badge
          pill
          :variant="
            data.item.delivery_note_received > 0
              ? 'light-success'
              : 'light-dark'
          "
          class="px-1 d-inline-flex align-items-center"
        >
          <feather-icon
            :icon="
              data.item.delivery_note_received > 0
                ? 'CheckCircleIcon'
                : 'XCircleIcon'
            "
            size="25"
            :color="data.item.delivery_note_received > 0 ? 'success' : 'black'"
          />
          <span
            :class="
              'text-capitalize font-weight-light badge-text ' +
              (data.item.delivery_note_received > 0
                ? 'text-success'
                : 'text-dark')
            "
          >
            {{ data.item.delivery_note_received > 0 ? "Si" : "No" }}
          </span>
        </b-badge>
      </template>
      <template #cell(total_gross)="{ value }">
        <span>{{ value | numberToLocalString }} €</span>
      </template>
      <template #cell(total_invoice)="{ value }">
        <span>{{ value | numberToLocalString }} €</span>
      </template>
      <template #cell(total_liquid)="{ value }">
        <span>{{ value | numberToLocalString }} €</span>
      </template>
      <template #cell(total_retention)="data">
        <div>{{ data.item.total_retention | numberToLocalString }} €</div>
        <div
          v-show="data.item.retention_expiration"
          class="font-small text-light"
        >
          vence {{ data.item.retention_expiration | formatDate }}
        </div>
      </template>
      <template #cell(actions)="data">
        <span>
          <feather-icon
            v-show="data.item.observations"
            :id="`popover-target-${data.item.id}`"
            icon="InfoIcon"
            size="18"
            class="text-indigo mr-1"
          />
          <b-popover :target="`popover-target-${data.item.id}`" triggers="hover" placement="top">
            <template #title>Observaciones</template>
            <pre style="background: #ffff;">{{ data.item.observations }}</pre>
          </b-popover>
          <b-link
            :disabled="
              !data.item.documentation || data.item.documentation.length <= 0
            "
            class="d-inline-block text-indigo"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Descargar factura"
              icon="DownloadIcon"
              size="18"
              @click.stop="handlePrintIconClick(data.item)"
            />
          </b-link>
          <b-link
            :to="{
              name: 'viewPurchaseInvoice',
              params: { id: data.item.id },
            }"
            class="d-inline-block px-1 text-indigo"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Ver"
              icon="EyeIcon" 
              size="18" 
            />
          </b-link>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,
              resourceAction:
                $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
            }"
            class="d-inline-block text-danger"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
              @click.stop="deletePurchaseOrder(data.item)"
            />
          </b-link>
        </span>
      </template>
      <template #footer="{ data }">
        <b-tr v-if="footerTotalsVisible" class="bg-action-blue">
          <b-td colspan="6" />
          <b-td class="text-right text-uppercase font-weight-bold">
            Totales:
          </b-td>
          <b-td class="text-uppercase font-weight-bold">
            {{ data.totals.total_gross || 0 | numberToLocalString }} €
          </b-td>
          <b-td class="text-uppercase font-weight-bold">
            {{ data.totals.total_retention || 0 | numberToLocalString }} €
          </b-td>
          <b-td class="text-uppercase font-weight-bold">
            {{ data.totals.total_liquid || 0 | numberToLocalString }} €
          </b-td>
          <b-td class="text-uppercase font-weight-bold">
            {{ data.totals.total_invoice || 0 | numberToLocalString }} €
          </b-td>
          <b-td colspan="1" />
          <b-td colspan="1" />
        </b-tr>
      </template>
    </BaseTable>
    <PurchaseInvoicesListFilters
      v-if="!hideToolbar"
      ref="purchase-invoices-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('purchaseInvoice/setFilters', $event)"
    />
  </div>
</template>

<script>
import PurchaseInvoicesApi from "@/api/purchase-invoices-api";
import DateTimeService from "@/shared/services/date-time-service";
import BaseTable from "@/components/ui/table/BaseTable.vue";
import StatusBadge from "@/components/status/badge/StatusBadge.vue";
import PurchaseInvoicesListFilters from "@/components/purchase-invoices/filters/PurchaseInvoicesListFilters.vue";
import ApiRestService from "@/api/base-api";
import { mapGetters } from 'vuex'

export default {
  name: "PurchaseInvoicesTable",
  components: { PurchaseInvoicesListFilters, StatusBadge, BaseTable },
  props: {
    orderId: {
      type: String,
      default: null,
    },
    deliveryNotesId: {
      type: String,
      default: null,
    },
    providerId: {
      type: Number,
      default: null,
    },
    withJobMode: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    footerTotalsVisible: {
      type: Boolean,
      default: false,
    },
    totalsConfiguration: {
      type: Array,
      default: () => [
        { colspan: 5 },
        { label: "TOTAL BRUTO", key: "total_gross" },
        { label: "TOTAL", key: "total_invoice" },
        { label: "TOTAL RETENCIÓN", key: "total_retention" },
        { colspan: 1 },
      ],
    },
  },
  data() {
    return {
      localLoading: false,
      filtersVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      filters: 'purchaseInvoice/getFilters',
    }),
    baseExcludeFilters() {
      const excludedFilters = [];
      if (this.providerId) {
        excludedFilters.push("provider_id");
      }

      if (this.orderId) {
        excludedFilters.push("order_id");
      }

      if (this.deliveryNotesId) {
        excludedFilters.push("delivery_note_id");
      }

      return excludedFilters;
    },
    baseFilters() {
      const filters = {
        ...this.filters,
        order_id: this.orderId,
        delivery_note_id: this.deliveryNotesId,
        with_job: this.withJobMode !== null ? Number(this.withJobMode) : null,
      };

      if (this.providerId) {
        filters.provider_id = this.providerId;
      }

      return filters;
    },
    columns() {
      const cols = [];
      if (this.withJobMode !== false) {
        cols.push({
          label: "JOB",
          key: "job",
          sortable: true,
          thStyle: { "min-width": "100px" },
        });
      }
      cols.push({
        label: "Nº factura proveedor",
        key: "provider_code",
        sortable: true
      });
      cols.push({
        label: "Numero",
        key: "number",
        sortable: true,
      });

      if (!this.providerId) {
        cols.push({
          label: "PROVEEDOR",
          key: "provider",
          sortable: true,
          thStyle: { "min-width": "220px" },
        });
      }

      return cols.concat([
        {
          label: "FECHA",
          key: "invoice_date",
          sortable: true,
        },
        {
          label: "VENCIMIENTO",
          key: "expiration_date",
          sortable: true,
        },
        {
          label: "ESTADO FACTURACIÓN",
          key: "status",
          sortable: true,
        },
        {
          label: "ALB. ENTREGADO",
          key: "delivery_note_received",
          sortable: true,
        },
        {
          label: "T. BRUTO",
          key: "total_gross",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
          thStyle: { "min-width": "100px" },
        },
        {
          label: "RETENCIÓN",
          key: "total_retention",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          label: "LÍQUIDO A PERCIBIR",
          key: "total_liquid",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: true,
        },
        {
          label: "T. FACTURA",
          key: "total_invoice",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
          thStyle: { "min-width": "120px" },
        },
        {
          label: "Acciones",
          key: "actions",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { "min-width": "160px" },
        },
      ]);
    },
  },
  methods: {
    getDueDateDaysText(dueDate) {
      if (!dueDate) {
        return "";
      }

      const days = DateTimeService.getDifferenceInDays(
        new Date(),
        new Date(dueDate)
      );
      return this.isDueDateExpired(dueDate)
        ? `Vencida ${days} dias`
        : `Vence en ${days} dias`;
    },
    isDueDateExpired(dueDate) {
      if (!dueDate) {
        return false;
      }

      return new Date() > new Date(dueDate);
    },
    loadData() {
      this.$refs["purchase-invoices-table"].loadData();
    },
    handleClearListFilters() {
      this.$store.commit('purchaseInvoice/setFilters', {})
      this.$refs["purchase-invoices-list-filters"].clearFilters();
    },
    async handlePrintIconClick(purchaseInvoice) {
      const { id, name } = purchaseInvoice.documentation[0];

      try {
        await ApiRestService.downloadArchive(id, name);
      } catch {}
    },
    async deletePurchaseOrder(purchaseInvoice) {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: `Se borrará el registro Nº${purchaseInvoice.number}.`,
      });

      if (!response.isConfirmed) {
        return;
      }

      this.localLoading = true;
      try {
        await PurchaseInvoicesApi.delete(purchaseInvoice.id);
        await this.loadData();
      } finally {
        this.localLoading = false;
      }
    },
  },
};
</script>
